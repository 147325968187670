import React from "react";
import Table from "../table/Table";
import { AiOutlineEye } from "react-icons/ai";
import { useState, useEffect } from "react";
import { GET_ALL_USERS, USERS_ACTIVE_INACTIVE } from "../../config/endpoints";
import { PACKAGE_STATEMENT } from "../../config/endpoints";
import { call } from "../../config/axios";

function AppStatus() {
  const [allUsers, setAllUsers] = useState([]);
  const [active, setActive] = useState();
  const [packageCost, setPackagesCost] = useState([]);

  const getAllUsers = async () => {
    const payload = {
      register_id: "company",
    };
    await call(GET_ALL_USERS, payload)
      .then((res) => {
        setAllUsers(res?.data?.data?.Items);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAllUsers();
  }, [active]);

  const getPackagescost = async () => {
    await call(PACKAGE_STATEMENT, {
      register_id: "company",
      package_requester_id: "reg-20240229175703556",
    })
      .then((res) => {
        setPackagesCost(res?.data?.data?.records);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getPackagescost();
  }, []);

  const handleBlockUnBlock = async (item, active) => {
    const payload = {
      register_id: item,
      active: !active,
    };
    await call(USERS_ACTIVE_INACTIVE, payload)
      .then((res) => {
        setActive(!active);
      })
      .catch((err) => console.log(err));
  };

  const cols = [
    {
      header: "CUSTOMER",
      field: "customer",
    },

    {
      header: "REGION",
      field: "region",
    },
    {
      header: "AMOUNT",
      field: "amount",
      clr: false,
    },
    {
      header: "BALANCE",
      field: "balance",
    },
    {
      header: "ROLE",
      field: "nameAndRole",
    },
    {
      header: "STATUS",
      field: "status",
      clr: true,
    },
    {
      header: "ACTION",
      field: "icon",
    },
  ];

  const modifiedAppstatusDetails = allUsers?.map((item) => ({
    ...item,
    customer: <div>{item.user_name}</div>,
    region: (
      <div>
        {item?.websites?.map((website) => (
          <div key={website.website_name}>{website.country_name}</div>
        ))}
      </div>
    ),
    nameAndRole: (
      <div>
        {item?.websites?.map((website) => (
          <div key={website.website_name}>
            {website.website_name} <br />{" "}
          </div>
        ))}
        <span className="role-color">{item?.account_role}</span>
      </div>
    ),
    dateAndTime: (
      <div>
        {item?.date} <br /> <span>{item?.time}</span>
      </div>
    ),
    status:
      item?.active === true ? (
        <div
          className="font-green custom-active-button px-2"
          onClick={() => {
            handleBlockUnBlock(item?.register_id, item?.active);
          }}
        >
          Active
        </div>
      ) : (
        <div
          className="custom-deactive-button px-2"
          onClick={() => {
            handleBlockUnBlock(item?.register_id, item?.active);
          }}
        >
          InActive
        </div>
      ),
    icon: <AiOutlineEye className="eye-icon-size cursor-pointer " />,
  }));

  const modifiedAppstatusDetails1 = packageCost?.map((item) => ({
    ...item,
    amount: item?.summary?.total_package_cost,
    balance:
      item?.summary?.total_package_cost - item?.summary?.final_package_cost,
  }));

  return (
    <div className="w-100 sidebar-bg rounded" style={{ height: "100%" }}>
      <div className="sidebar-bg rounded py-1">
        <Table
          columns={cols}
          data={modifiedAppstatusDetails}
          data1={modifiedAppstatusDetails1}
        />
      </div>
    </div>
  );
}

export default AppStatus;
