import React, { useState } from "react";
import Table from "../table/Table";
import { TbLockQuestion } from "react-icons/tb";
import { MdOutlineBlock } from "react-icons/md";
import AddDirectorsPopup from "../Popups/AddDirectorsPopup";

function Addtt() {
  const [showAddDirectorPopup, setShowAddDirectorPopup] = useState(false);
  const handleAddDirectionPopup = () => {
    setShowAddDirectorPopup(true);
  };
  const ADDTT_DETAILS = [
    {
      role: <div className="role-color">Designer</div>,
      username: "Jayantha",
      inused: "India-Hyd",
      password: "abcd1234",
      website: "Broadcasting & Adds",
      status: "In-Active",
      icon: (
        <div className="d-flex align-items-center justify-content-evenly">
          <TbLockQuestion className="mx-1 eye-icon-size cursor-pointer " />
          <MdOutlineBlock className="mx-1 eye-icon-size border-borderradius cursor-pointer " />
        </div>
      ),
    },
    {
      role: <div className="role-color">Designer</div>,
      username: "Jayantha",
      inused: "India-Hyd",
      password: "abcd1234",
      website: "Broadcasting & Adds",
      status: "Active",
      icon: (
        <div className="d-flex align-items-center justify-content-evenly">
          <TbLockQuestion className="mx-1 eye-icon-size cursor-pointer " />
          <MdOutlineBlock className="mx-1 eye-icon-size border-borderradius cursor-pointer " />
        </div>
      ),
    },
  ];

  const cols = [
    {
      header: "ROLE",
      field: "role",
    },
    {
      header: "USER NAME",
      field: "username",
    },
    {
      header: "IN USED",
      field: "inused",
    },
    {
      header: "PASSWORD",
      field: "password",
    },
    {
      header: "WEBSITE",
      field: "website",
    },

    {
      header: "STATUS",
      field: "status",
      clr: true,
    },
    {
      header: "Action",
      field: "icon",
    },
  ];

  return (
    <div className="p-4 w-100">
      <h6 className="h6 font-grey">ADD TT</h6>
      <div className="sidebar-bg rounded">
        <div className="d-flex align-items-center justify-content-between">
          <h6 className="th-color ps-2">
            Add Owner Team Login for Broadcasing & Ads / Tours & Tournaments
          </h6>
          <div className=" d-flex justify-conten-between">
            <div className="containaer-fluid px-2 w-30">
              <form className="d-flex" role="search">
                <input
                  className="search-width m-1 mt-3 p-2 text-white w-100 sidebar-bg borderr rounded small-font"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
              </form>
            </div>
            <div className="row justify-content-md-center m-2 p-1">
              <div
                className="active text-white col-md-auto small-font justify-content-between p-2 px-4 m-1 cursor-pointer"
                onClick={() => handleAddDirectionPopup()}
              >
                +Add New
              </div>
            </div>
          </div>
        </div>
        <Table columns={cols} data={ADDTT_DETAILS} />
      </div>
      <AddDirectorsPopup
        showAddDirectorPopup={showAddDirectorPopup}
        setShowAddDirectorPopup={setShowAddDirectorPopup}
        heading="Add Owner Team"
        firstTextBox="Select Area *"
        firstSelect="Role"
      />
    </div>
  );
}

export default Addtt;
