import { Modal } from "react-bootstrap";

function PaymentImagePopup(props) {
  const {
    showScreenshotImg,
    setShowScreenshotImg,
    documentView,
    setDocumentView,
  } = props;

  const fileName = documentView?.split("/").pop();
  const fileExtension = fileName?.split(".").pop();

  const handlePopupClose = () => {
    setDocumentView("");
    setShowScreenshotImg(false);
  };

  return (
    <div>
      <Modal
        size="md"
        show={showScreenshotImg}
        onHide={handlePopupClose}
        centered
        className="match-share-modal payment-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        {fileExtension === "pdf" && <img src={documentView} alt="" />}
      </Modal>
    </div>
  );
}

export default PaymentImagePopup;
