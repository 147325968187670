import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { persistStore } from "redux-persist";
import commonReducer from "./reducers/commonReducer";

const rootReducer = combineReducers({
  common: commonReducer,
});

const logger = (store) => (next) => (action) => {
  return next(action);
};

const thunk =
  (args) =>
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    if (typeof action === "function") {
      return action(dispatch, getState, args);
    }
    return next(action);
  };

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk(), logger))
);
const persistor = persistStore(store);

export { persistor };
