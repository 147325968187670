import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
//import DatePicker from "react-datepicker";
import { FaRegCalendarAlt } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import { MdUpload } from "react-icons/md";
import { call } from "../../config/axios";
import { ADD_OFFERS } from "../../config/endpoints";
import { GET_ALL_WEBSITES } from "../../config/endpoints";
import { GET_ALL_USERS } from "../../config/endpoints";
import { GET_ALL_PAYMENTS } from "../../config/endpoints";
import { GENERATE_SIGNED_URL } from "../../config/endpoints";
import MatchSubmitPopup from "../../matchpopups/MatchSubmitPopup";

function OfferPosterAdds() {
  const ImageBaseUrl =
    "https://we2-call-images-singapore.s3.ap-southeast-1.amazonaws.com";
  const [allOffers, setallOffers] = useState({});
  const [posterId, setPosterId] = useState("");
  const [singedUrl, setSignedUrl] = useState("");
  const [uploadImage, setuploadImage] = useState([]);
  const [profileImage, setProfileImage] = useState("");
  const [error, setError] = useState("");
  const [offerSubmitPopup, setOfferSubmitPopup] = useState(false);

  const uploadfileInputRef = useRef(null);

  const handleUploadFileSelect = (e) => {
    const file = e.target.files[0];
    setProfileImage(file);
    generateSignedUrl();
  };
  const handleUploadButtonClick = () => {
    uploadfileInputRef.current.click();
  };
  const handelOffers = async (status) => {
    if (
      !(
        allOffers?.website_name ||
        allOffers?.country_name ||
        allOffers?.user ||
        allOffers?.notification_type ||
        allOffers?.description
      )
    ) {
      return setError("missing required fields");
    } else {
      setError("");
      await call(ADD_OFFERS, {
        register_id: "company",
        website_name: allOffers?.website_name,
        user: allOffers?.user,
        country_name: allOffers?.country_name,
        notification_type: allOffers?.notification_type,
        description: allOffers?.description,
        start_date: allOffers?.start_date,
        end_date: allOffers?.end_date,
        publish_date: allOffers?.publish_date,
        upload_image: `${ImageBaseUrl}/${"posters-images"}/${posterId}.png`,
        status: status,
        active: status,
      }).then(async (res) => {
        setallOffers(res?.data);
        setOfferSubmitPopup(true);
        setTimeout(() => {
          setOfferSubmitPopup(false);
        }, 2000);
        singedUrl &&
          profileImage &&
          (await fetch(singedUrl, {
            method: "PUT",
            body: profileImage,
            headers: {
              "Content-Type": "image/jpeg",
              "cache-control": "public, max-age=0",
            },
          })
            .then((res) => {})
            .catch((err) => {
              console.log("err: ", err);
            }));
      });
    }
  };

  const generateSignedUrl = async () => {
    setuploadImage(true);
    const posetNewId = new Date().getTime();
    await call(GENERATE_SIGNED_URL, {
      register_id: `${posetNewId}`,
      event_type: "user_profile_image",
      folder_name: "posters-images",
    })
      .then(async (res) => {
        setuploadImage(false);
        let url = res?.data?.data?.result?.signed_url;
        setSignedUrl(url);
        setPosterId(posetNewId);
      })
      .catch((err) => {
        setuploadImage(false);
        console.log("generating signed url error", err);
      });
  };

  useEffect(() => {
    setallOffers();
  }, []);

  const handelChange = (e) => {
    setallOffers({
      ...allOffers,
      [e.target.name]: e.target.value,
    });
  };

  const [websiteNames, setwebsiteNames] = useState([]);
  const getwebsiteNames = async () => {
    const payload = {
      register_id: "company",
    };
    await call(GET_ALL_WEBSITES, payload)
      .then((res) => {
        setwebsiteNames(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getwebsiteNames();
  }, []);

  const [allUsers, setgetallUsers] = useState([]);
  const getallUsers = async () => {
    const payload = {
      register_id: "company",
    };
    await call(GET_ALL_USERS, payload)
      .then((res) => {
        setgetallUsers(res?.data?.data?.Items);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getallUsers();
  }, []);

  const [allPayments, setAllPayments] = useState([]);
  const getPaymentWay = async () => {
    const payload = {
      register_id: "company",
    };
    await call(GET_ALL_PAYMENTS, payload)
      .then((res) => {
        setAllPayments(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPaymentWay();
  }, []);

  return (
    <div className="p-4">
      <Container fluid className="my-2">
        <Row>
          <Col className="ps-0">
            <Container>
              <Row>
                <Col>
                  <div>
                    <div className="clr-grey small-font my-2">
                      Select Website *
                    </div>
                    <select
                      name="website_name"
                      id="website_name"
                      value={allOffers?.website_name || ""}
                      onChange={(e) => handelChange(e)}
                      className="w-100  small-font input-btn-bg px-2 py-3 all-none rounded all-none"
                    >
                      <option value="">Select</option>
                      <option value="All">All</option>
                      {websiteNames?.map((obj, i) => (
                        <option key={i} value={obj.web_url}>
                          {obj.web_url}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div>
                    <div className="clr-grey small-font my-2">
                      Select User/Admin *
                    </div>
                    <select
                      name="user"
                      id="user"
                      value={allOffers?.user || ""}
                      onChange={(e) => handelChange(e)}
                      className="w-100  small-font input-btn-bg px-2 py-3 rounded all-none"
                    >
                      <option value="">Select</option>
                      <option value="All">All</option>
                      {allUsers &&
                        allUsers?.length > 0 &&
                        allUsers?.map((obj, i) => (
                          <option key={i} value={obj?.user_name}>
                            {obj?.user_name}
                          </option>
                        ))}
                    </select>
                  </div>
                </Col>
                <Col>
                  <div>
                    <div className="clr-grey small-font my-2">
                      Select Country *
                    </div>
                    <select
                      name="country_name"
                      id="country_name"
                      value={allOffers?.country_name || ""}
                      onChange={(e) => handelChange(e)}
                      className="w-100  small-font input-btn-bg px-2 py-3 all-none rounded all-none"
                    >
                      <option>Select</option>
                      <option>All</option>
                      {allPayments &&
                        allPayments?.length > 0 &&
                        allPayments?.map((obj, i) => (
                          <option key={i} value={obj?.country_name}>
                            {obj?.country_name}
                          </option>
                        ))}
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div>
                    <div className="clr-grey small-font my-2">
                      Notification Type *
                    </div>
                    <select
                      name="notification_type"
                      id="notification_type"
                      value={allOffers?.notification_type || ""}
                      onChange={(e) => handelChange(e)}
                      className="w-100  small-font input-btn-bg px-2 py-3 all-none rounded all-none"
                    >
                      <option value="select">Select</option>
                      <option value="Demo">Demo</option>
                      <option value="Demo">Demo</option>
                      <option value="Demo">Demo</option>
                      <option value="Demo">Demo</option>
                    </select>
                  </div>
                </Col>
                <Col>
                  <div className="small-font clr-grey my-2">
                    Upload Screenshot
                  </div>
                  <div
                    className="w-100  small-font input-btn-bg p-3 my-2 all-none rounded all-none d-flex flex-row justify-content-between align-items-center"
                    onClick={handleUploadButtonClick}
                    disabled={uploadImage}
                  >
                    <div className="small-font font-grey">
                      Upload Screenshot
                    </div>
                    <input
                      type="file"
                      ref={uploadfileInputRef}
                      style={{ display: "none" }}
                      onChange={handleUploadFileSelect}
                      className="login-inputs"
                    ></input>
                    <MdUpload className="upload-icon" />
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col className="pe-0">
            <div className="small-font my-2 clr-grey">Description</div>
            <textarea
              type="text"
              name="description"
              id="description"
              value={allOffers?.description || ""}
              onChange={(e) => handelChange(e)}
              placeholder="Type Here"
              className="w-100  small-font input-btn-bg rounded all-none py-3 px-2 h-85"
            ></textarea>
          </Col>
        </Row>
      </Container>
      <hr className="hr-line my-2" />
      <div className="w-fit-content rounded-pill medium-font py-2 px-4 completed-btn text-center">
        Publish Details
      </div>
      <Container fluid className="mt-2">
        <Row className="w-100">
          <Col className="col-lg-2 col-md-3">
            <div>
              <div className="medium-font mb-2 clr-grey">Active From</div>
              <div className=" d-flex flex-row w-100  small-font input-btn-bg px-2 py-2 all-none rounded all-none align-items-center">
                <input
                  type="date"
                  className="login-input all-none w-100"
                  name="start_date"
                  value={allOffers?.start_date || ""}
                  onChange={(e) => handelChange(e)}
                ></input>
                {/* <FaRegCalendarAlt className="upload-icon p-1 font-size-30" /> */}
              </div>
            </div>
          </Col>
          <Col className="col-lg-2 col-md-3">
            <div>
              <div className="medium-font mb-2 clr-grey">To</div>
              <div className="w-100  small-font input-btn-bg px-2 py-2 all-none rounded all-none d-flex flex-row align-items-center">
                <input
                  className="login-input all-none w-100"
                  type="date"
                  name="end_date"
                  value={allOffers?.end_date || ""}
                  onChange={(e) => handelChange(e)}
                ></input>
                {/* <FaRegCalendarAlt className="upload-icon p-1 font-size-30" /> */}
              </div>
            </div>
          </Col>
          <Col className="col-lg-2 col-md-3">
            <div>
              <div className="medium-font mb-2 clr-grey">Publish Date</div>
              <div className="w-100  small-font input-btn-bg px-2 py-2 all-none rounded all-none d-flex flex-row align-items-center">
                <input
                  type="date"
                  className="login-input all-none w-100"
                  name="publish_date"
                  value={allOffers?.publish_date || ""}
                  onChange={(e) => handelChange(e)}
                ></input>
                {/* <FaRegCalendarAlt className="upload-icon p-1 font-size-30" /> */}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="d-flex align-items-center flex-row px-3 mt-3">
        <input type="checkbox" />
        <div className="medium-font mx-2 clr-grey">Publish Now</div>
      </div>
      {error && <div className="danger">{error}</div>}
      <div className="row w-100 d-flex flex-row justify-content-between my-3">
        <div className="col-sm d-flex flex-row">
          <button
            type="submit"
            className="add-button medium-font rounded px-3 py-2 mx-2 all-none "
            onClick={() => handelOffers(true)}
          >
            Publish
          </button>

          <button
            type="submit"
            className="msg-deactive-button  medium-font rounded  mx-2 all-none px-3 py-3"
            onClick={() => handelOffers(false)}
          >
            Save As Draft
          </button>
        </div>
        <div className="col-sm d-flex justify-content-end">
          <button
            type="submit"
            className="msg-deactive-button  medium-font rounded  mx-2 all-none px-3 py-3"
          >
            Cancel
          </button>
        </div>
        <MatchSubmitPopup
          header={"Offer Created Submitfully"}
          state={offerSubmitPopup}
          setState={setOfferSubmitPopup}
        />
      </div>
    </div>
  );
}

export default OfferPosterAdds;
