import { useState } from "react";
import Table from "../table/Table";

function PkgDetailsTable() {
  const [activeMystatementButtons, setActiveMystatementButtons] = useState(0);
  const handleMystatement = (index) => {
    setActiveMystatementButtons(index);
  };
  const MYSTATEMENT_BUTTONS = [
    {
      buttonName: "Hourly",
    },
    {
      buttonName: "Monthly",
    },
    {
      buttonName: "Yearly",
    },
  ];
  const HOURLY_HEADINGS = [
    { header: "DATE", field: "date" },
    { header: "INACTIVE DATE", field: "exp_date" },
    { header: "PKG NAME", field: "pkg_name" },
    { header: "PKG COST", field: "pkg_cost" },
    { header: "PKG HOURS", field: "pkg_hours" },
    { header: "LTD MEM", field: "ltd_members" },
    { header: "DISCOUNT", field: "discount" },
    { header: "CARD DETAILS", field: "details" },
    { header: "STATUS", field: "status" },
  ];

  const HOURLY_DATA = [
    {
      date: (
        <div>
          <div>20-03-2024</div>
          <div>12:00:00 PM</div>
        </div>
      ),
      exp_date: (
        <div>
          <div>20-03-2024</div>
          <div>12:00:00 PM</div>
        </div>
      ),
      pkg_name: "Standard",
      pkg_cost: "1000",
      pkg_hours: "10 Hr",
      ltd_members: "10",
      discount: "5%",
      details: (
        <div>
          <div>No of memebers allows to meeting</div>
          <div>No of personal meetings</div>
          <div>No of meetings</div>
          <div>Call type audio/video</div>
        </div>
      ),
      status: "Active",
    },
  ];

  return (
    <div className="sidebar-bg rounded p-3">
      <div className="row d-flex align-items-center">
        {MYSTATEMENT_BUTTONS?.map((item, index) => (
          <div
            className="col-2"
            key={index}
            onClick={() => handleMystatement(index)}
          >
            <div
              className={`medium-font accounts-box font-grey p-2  rounded-top text-center ${
                activeMystatementButtons === index ? "active" : ""
              }`}
            >
              {item.buttonName}
            </div>
          </div>
        ))}
      </div>
      <hr className="hr-line mt-2" />
      {activeMystatementButtons === 0 && (
        <Table columns={HOURLY_HEADINGS} data={HOURLY_DATA} />
      )}
      {activeMystatementButtons === 1 && (
        <Table columns={HOURLY_HEADINGS} data={HOURLY_DATA} />
      )}
      {activeMystatementButtons === 2 && (
        <Table columns={HOURLY_HEADINGS} data={HOURLY_DATA} />
      )}
    </div>
  );
}

export default PkgDetailsTable;
