import Sockette from "sockette";
import { chat_base_url } from "../../config/config_local";

let ws;
let events;
let isConnecttionOpen;

export const open = (events = {}) => {
  events = events;
  return new Promise((resolve) => {
    ws = new Sockette(`${chat_base_url}?userid=${"company"}`, {
      timeout: 5e3,
      maxAttempts: 1,
      onopen: (e) => {
        isConnecttionOpen = true;
        resolve();
      },
      onmessage: (e) => console.log("Message Received:", e),
      onreconnect: (e) => console.log("Reconnecting...", e),
      onmaximum: (e) => console.log("Stop Attempting!", e),
      onclose: (e) => {
        console.log("Closed!", e);
        isConnecttionOpen = false;
      },
      onerror: (e) => console.log("Error:", e),
      ...events,
    });
  });
};
//Send Message
export const send = async (message, toUser) => {
  if (!isConnecttionOpen) {
    await open(events);
  }
  ws.send(
    JSON.stringify({
      action: "onmessage",
      message,
      from_user: "company",
      to_user: toUser, // TESTA-ADMIN
    })
  );
};

export const close = () => {
  ws.close();
};
