import PackagesSportsButtons from "./PackagesSportsButtons";
function MyStatement() {
  return (
    <div className="p-4 w-100">
      <h6 className="h6 font-grey">My Statement</h6>
      <PackagesSportsButtons />
    </div>
  );
}

export default MyStatement;
