import React, { useEffect, useState } from "react";
import { FaLocationDot } from "react-icons/fa6";
import Table from "../table/Table";
import { AiOutlineEdit } from "react-icons/ai";
import { MdDateRange } from "react-icons/md";
import { BiTimeFive } from "react-icons/bi";
import { GET_MATCHES_DATA, GET_ALL_WEBSITES } from "../../config/endpoints";
import { CREATE_OFFLINE_MATCHES } from "../../config/endpoints";
import { call } from "../../config/axios";
import MatchPopup from "../Popups/MatchPopup";
import MatchSubmitPopup from "../../matchpopups/MatchSubmitPopup";
import moment from "moment";

function Creatematch() {
  const [createMatch, setcreateMatch] = useState({});
  const [selectedMatch, setSelectedMatch] = useState([]);
  const [showMatchOpen, setShowMatchOpen] = useState(false);
  const [Error, setError] = useState(false);
  const [oversChange, setOversChange] = useState({});
  const [matchSubmitPopup, setMatchSubmitPopup] = useState(false);
  const [liveMatches, setLiveMatches] = useState([]);
  const [upcomingMatches, setUpcommingMatches] = useState([]);
  const [todayMatches, setTodayMatches] = useState([]);
  const [websiteNames, setwebsiteNames] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const matchType = [
    { name: "T10", first: [1, 4, 5], second: [2, 3] },
    { name: "T20", first: [1, 4, 5, 10, 15], second: [2, 3, 5, 10, 15] },
    {
      name: "ODI",
      first: [1, 4, 5, 6, 9, 12, 15, 18, 20, 25],
      second: [2, 5, 8, 12, 15, 20, 25, 50],
    },
    { name: "testMatch", first: ["No Fancy"], second: ["No Fancy"] },
  ];

  const selectOvers = matchType.filter(
    (i) => i.name === createMatch?.macth_type
  );

  const [firstEdit, setFirstEdit] = useState(false);
  const [secondEdit, setSecondEdit] = useState(false);

  const handleOversChange = (e) => {
    setOversChange({ ...oversChange, [e.target.name]: [e.target.value] });
    if (e.target.name === "first_fancy") {
      setFirstEdit(true);
    }

    if (e.target.name === "second_fancy") {
      setSecondEdit(true);
    }
  };

  const MatchTypeDropdown = [
    {
      heading: "1st Inn",
      cspan: "col",
      name: "first_fancy",
      overs: selectOvers[0]?.first,
    },
    {
      heading: "2nd Inn",
      cspan: "col",
      name: "second_fancy",
      overs: selectOvers[0]?.second,
    },
  ];

  const handleSubmitMatch = async () => {
    if (
      !createMatch?.series_name ||
      !createMatch?.sports_name ||
      !createMatch?.team1 ||
      !createMatch?.team2 ||
      !createMatch?.match_place ||
      !createMatch?.stadium ||
      !createMatch?.date ||
      !createMatch?.time
    ) {
      return setError("Missing Required feilds");
    } else {
      await call(CREATE_OFFLINE_MATCHES, {
        register_id: "company",
        series_name: createMatch?.series_name,
        account_role: "company",
        team1: createMatch?.team1,
        team2: createMatch?.team2,
        sport_name: createMatch?.sports_name,
        client_name: createMatch?.client_name,
        match_place: createMatch?.match_place,
        stadium: createMatch?.stadium,
        gender: createMatch?.gender === "Female" ? "F" : "M",
        date: createMatch?.date,
        time: createMatch?.time,
        game_object: {
          first_innings_fancy_overs:
            firstEdit === false
              ? selectOvers[0]?.first
              : oversChange.first_fancy,
          second_innings_fancy_overs:
            secondEdit === false
              ? selectOvers[0]?.second
              : oversChange.second_fancy,
          match_type: createMatch?.macth_type,
        },
      }).then((res) => {
        if (res?.data?.statusCode === 200) {
          setMatchSubmitPopup(true);
          setcreateMatch({});
          setRefresh((prev) => !prev);
          setTimeout(() => {
            setMatchSubmitPopup(false);
          }, 2000);
        }
      });
    }
  };
  useEffect(() => {
    setcreateMatch();
  }, []);

  const handelChange = (e) => {
    setError("");
    setcreateMatch({ ...createMatch, [e.target.name]: e.target.value });
  };

  const top_cricket_countries = [
    {
      headName: "Team1",
      name: "team1",
    },
    {
      headName: "Team2",
      name: "team2",
    },
  ];

  const sportsDropdowns = [
    {
      headName: "Sports Name",
      keyValue: "sports_name",
      options: <option value="cricket">Cricket</option>,
    },
  ];

  const cols = [
    {
      header: "SERIES NAME",
      field: "seriesname",
    },
    {
      header: "TEAM",
      field: "team",
    },
    {
      header: "SPORTS NAME",
      field: "sportsname",
    },
    {
      header: "LIVE SCOREBOARD",
      field: "livescoreboard",
    },
    {
      header: "MATCH PLACE",
      field: "matchplace",
    },
    {
      header: "DATE",
      field: "date",
    },
    {
      header: "TIME",
      field: "time",
    },
    {
      header: "ACTION",
      field: "icon",
    },
  ];

  const getAllMatches = async () => {
    const payload = {
      register_id: "company",
      account_role: "company",
    };
    await call(GET_MATCHES_DATA, payload)
      .then((res) => {
        let result = res?.data?.data;
        setLiveMatches(result?.liveMatches);
        setUpcommingMatches(result?.upCommingMatches);
        setTodayMatches(result?.todaysMatches);
      })
      .catch((err) => console.log(err));
  };
  const allMatchesData = [...liveMatches, ...upcomingMatches, ...todayMatches];

  useEffect(() => {
    getAllMatches();
  }, [refresh]);

  const getwebsiteNames = async () => {
    await call(GET_ALL_WEBSITES, { register_id: "company" })
      .then((res) => {
        setwebsiteNames(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getwebsiteNames();
  }, []);
  console.log(allMatchesData, "allMatchesData");
  const modifiedCreatematchDetails = allMatchesData?.map((item) => ({
    team: <div className="role-color">{item?.match_name}</div>,
    date: moment(item?.matchTimeStamp).format("DD-MM-YYYY"),
    time: moment(item?.matchTimeStamp).format("hh:mm:ss A"),
    seriesname: item?.series_name,
    sportsname: item?.sport_name,
    matchplace: item?.match_place,
    icon: (
      <AiOutlineEdit
        className="cursor-pointer eye-icon-size"
        onClick={() => {
          handleMatchOpen(item);
        }}
      />
    ),
  }));

  const handleMatchOpen = (item) => {
    setShowMatchOpen(true);
    setSelectedMatch(item);
  };

  return (
    <div className="p-4 w-100">
      <h6 className="h6 font-grey p-2">CREATE MATCH</h6>
      <div className="sidebar-bg rounded">
        <div className="row gutter-1rem p-2">
          <div className="col">
            <div className="th-color small-font">Series Name</div>
            <div className="sport-management-input d-flex ">
              <input
                placeholder="Enter"
                className="w-90 th-color small-font p-1"
                name="series_name"
                id="series_name"
                value={createMatch?.series_name || ""}
                onChange={(e) => handelChange(e)}
              />
            </div>
          </div>
          {sportsDropdowns?.map((item, index) => {
            return (
              <div key={index} className="col">
                <div className="th-color small-font">{item.headName}</div>
                <select
                  className="sport-management-input d-flex p-1 th-color small-font w-100 sport-management-select"
                  name={item?.keyValue}
                  onChange={(e) => handelChange(e)}
                >
                  <option>Select</option>
                  {item.options}
                </select>
              </div>
            );
          })}
          {top_cricket_countries?.map((item, index) => {
            return (
              <div key={index} className="col">
                <div className="th-color small-font">{item.headName}</div>
                <div className="sport-management-input d-flex justify-content-between p-1 th-color small-font">
                  <input
                    className="w-90 th-color small-font p-1"
                    onChange={(e) => handelChange(e)}
                    placeholder="Enter Team"
                    name={item?.name}
                    value={createMatch?.[item?.name] || ""}
                  ></input>
                </div>
              </div>
            );
          })}
          <div className="col">
            <div className="th-color small-font">Match place</div>
            <div className="sport-management-input d-flex justify-content-between p-1 th-color small-font">
              <input
                placeholder="Enter"
                className="w-90 th-color small-font p-1"
                name="match_place"
                value={createMatch?.match_place || ""}
                onChange={(e) => handelChange(e)}
              />
              <FaLocationDot className="bluecolor-text medium-font" />
            </div>
          </div>
        </div>
        <div className="row gutter-1rem mt-1 p-2">
          <div className="col">
            <div className="th-color small-font">Stadium</div>
            <div className="sport-management-input d-flex p-1 th-color small-font">
              <input
                placeholder="Enter"
                className="w-90 th-color small-font p-1"
                name="stadium"
                id="stadium"
                value={createMatch?.stadium || ""}
                onChange={(e) => handelChange(e)}
              />
            </div>
          </div>
          <div className="col">
            <div className="th-color small-font">Gender</div>
            <div className="sport-management-input d-flex p-1 th-color small-font p-1">
              <select
                className="sport-management-input d-flex p-1 th-color small-font w-100 sport-management-select"
                name="gender"
                id="gender"
                value={createMatch?.gender || ""}
                onChange={(e) => handelChange(e)}
              >
                <option value="Male">Male</option>
                <option value="Female">FeMale</option>
              </select>
            </div>
          </div>
          <div className="col">
            <div className="th-color small-font">Date</div>
            <div className="sport-management-input d-flex p-1 th-color small-font">
              <input
                className="w-100 m-auto th-color small-font p-1"
                type="date"
                name="date"
                value={createMatch?.date || ""}
                onChange={(e) => handelChange(e)}
              ></input>
              {/* <MdDateRange className="bluecolor-text medium-font" /> */}
            </div>
          </div>
          <div className="col">
            <div className="th-color small-font">Time</div>
            <div className="sport-management-input d-flex p-1 th-color small-font">
              <input
                id="appt-time"
                type="time"
                step="2"
                className="w-100 match-input margin-negitive-15px"
                placeholder="Time"
                name="time"
                value={createMatch?.time || ""}
                onChange={(e) => handelChange(e)}
              ></input>
              {/* <BiTimeFive className="bluecolor-text medium-font" /> */}
            </div>
          </div>
          <div className="col">
            <div className="th-color small-font">Websites</div>
            <div className="sport-management-input d-flex p-1 th-color small-font p-1">
              <select
                className="sport-management-input d-flex p-1 th-color small-font w-100 sport-management-select"
                name="website_name"
                id="website_name"
                value={createMatch?.website_name || ""}
                onChange={(e) => handelChange(e)}
              >
                <option>select</option>
                <option value="All">All</option>
                {websiteNames?.map((obj, index) => (
                  <option key={index} value={obj.web_url}>
                    {obj.web_url}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="row gutter-1rem mt-1 p-2 th-color small-font">
          <div className="col">
            <div>Macth Type</div>
            <div className="sport-management-input d-flex p-1">
              <select
                className="sport-management-input d-flex p-1 w-100 sport-management-select"
                name="macth_type"
                onChange={(e) => handelChange(e)}
              >
                <option value="select">
                  {createMatch?.macth_type || "Select"}
                </option>
                <option value="T10">T10</option>
                <option value="T20">T20</option>
                <option value="ODI">ODI</option>
                <option value="testMatch">TEST-MATCH</option>
              </select>
            </div>
          </div>
          {MatchTypeDropdown.map((item, index) => {
            return (
              <div className={item.cspan} key={index}>
                <div>{item.heading}</div>
                <input
                  className="sport-management-input d-flex p-1 w-100 sport-management-select meetings-heading"
                  name={item.name}
                  value={item?.overs || ""}
                  onChange={(e) => handleOversChange(e)}
                ></input>
              </div>
            );
          })}
          <div className="col d-flex align-items-end th-color small-font">
            {Error && <div className="danger">{Error}</div>}
            <div
              className="cursor-pointer sport-management-input w-100 th-color small-font d-flex justify-content-center align-items-center bg-yellow"
              onClick={() => handleSubmitMatch()}
            >
              Submit
            </div>
          </div>
          <div className="col"></div>
        </div>
        <div className="font-weight-bold px-2 p-2 pt-0 mt-0 th-color">
          Your Matches
        </div>
        <Table columns={cols || []} data={modifiedCreatematchDetails} />

        <MatchSubmitPopup
          header={"Match Created Successfully"}
          state={matchSubmitPopup}
          setState={setMatchSubmitPopup}
        />
        <MatchPopup
          selectedMatch={selectedMatch}
          showMatchOpen={showMatchOpen}
          setShowMatchOpen={setShowMatchOpen}
          setRefresh={setRefresh}
        />
      </div>
    </div>
  );
}

export default Creatematch;
