import * as actionTypes from "../actions/actionConstants";

const initialState = { loading: false };

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PACKAGE_INPUT_DATA:
      return {
        ...state,
        stored_package_data: action.data,
      };
    default:
      return state;
  }
};

export default commonReducer;
