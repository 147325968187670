import Table from "./../table/Table";
import { AiOutlineEye } from "react-icons/ai";
import { call } from "../../config/axios";
import { useEffect, useState } from "react";
import { PACKAGE_STATEMENT } from "../../config/endpoints";

function SuperAdminTable() {
  const [Packages, setPackages] = useState([]);
  const getPackages = async () => {
    await call(PACKAGE_STATEMENT, {
      register_id: "reg-20240305123058870",
    })
      .then((res) => {
        setPackages(res?.data?.data?.records);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getPackages();
  }, []);

  const SUPER_ADMIN_ROW_DATA = Packages?.map((item) => ({
    userName: (
      <div className="d-flex flex-column">
        <div className=" cursor-pointer">{item?.summary.requester_name}</div>
        <div className="role-color">{item?.summary?.requester_role}</div>
      </div>
    ),
    //buySportsChips: (
    //       <div className="d-flex flex-column">
    //         <div>100000</div>
    //         <div className="bluecolor-text">Fixed</div>
    //       </div>
    //     ),
    // buyCasinoChips: (
    //       <div className="d-flex flex-column">
    //         <div>100000</div>
    //         <div className="bluecolor-text">5%</div>
    //       </div>
    //     ),
    //rentFixed: <div className="font-green">5000</div>,
    //     shareRoyalty: <div className="font-green">5000</div>,
    //     totalAmount: <div className="font-green">5000</div>,
    pkgAmount: item?.summary?.total_package_cost,
    paidAmount: item?.summary?.final_package_cost,
    balanceAmount:
      item?.summary?.total_package_cost - item?.summary?.final_package_cost,
    viewDownline: <AiOutlineEye className="eye-icon cursor-pointer" />,
  }));

  const SUPER_ADMIN_COLUMN_DATA = [
    {
      header: "USER NAME/ROLE",
      field: "userName",
    },
    {
      header: "PKG AMOUNT",
      field: "pkgAmount",
    },
    {
      header: "BUY SPORTS CHIPS",
      field: "buySportsChips",
    },
    {
      header: "BUY CASINO CHIPS",
      field: "buyCasinoChips",
    },
    {
      header: "RENT/FIXED",
      field: "rentFixed",
    },
    {
      header: "SHARE/ROYALTY",
      field: "shareRoyalty",
    },
    {
      header: "TOTAL AMOUNT",
      field: "totalAmount",
    },
    {
      header: "PAID",
      field: "paidAmount",
    },
    {
      header: "BALANCE",
      field: "balanceAmount",
    },
    {
      header: "VIEW DOWNLINE",
      field: "viewDownline",
    },
  ];
  return (
    <div>
      <Table data={SUPER_ADMIN_ROW_DATA} columns={SUPER_ADMIN_COLUMN_DATA} />
      <div className="th-color d-flex align-items-center justify-content-center">
        TOTAL
      </div>
      <div className="th-color d-flex align-items-center justify-content-center">
        PAGINATION
      </div>
    </div>
  );
}

export default SuperAdminTable;
