import React from "react";
import Table from "../table/Table";
import { Images } from "../../images";
import { call } from "../../config/axios";
import { useState, useEffect } from "react";
import { GET_ALL_USERS, USERS_ACTIVE_INACTIVE } from "../../config/endpoints";

function Meetingshedule() {
  const [allUsers, setAllUsers] = useState([]);
  const [active, setActive] = useState();

  const getAllUsers = async () => {
    const payload = {
      register_id: "company",
    };
    await call(GET_ALL_USERS, payload)
      .then((res) => {
        setAllUsers(res?.data?.data?.Items);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAllUsers();
  }, [active]);

  const handleBlockUnBlock = async (item, active) => {
    const payload = {
      register_id: item,
      active: !active,
    };
    await call(USERS_ACTIVE_INACTIVE, payload)
      .then((res) => {
        setActive(!active);
      })
      .catch((err) => console.log(err));
  };

  const cols = [
    {
      header: "NAME & ROLE",
      field: "icon",
    },
    {
      header: "MEETINGSS",
      field: "meetings",
    },
    {
      header: "STATUS",
      field: "status",
      clr: true,
    },
  ];

  const modifiedMeetingsheduleDetails = allUsers?.map((item) => ({
    ...item,
    icon: (
      <div>
        {item?.user_name} <br />{" "}
        <span className="role-color">
          {" "}
          {item?.websites?.map((website) => (
            <div key={website.website_name}>
              {website.website_name} <br />{" "}
            </div>
          ))}
          -{item?.account_role}
        </span>
      </div>
    ),
    meetings: <div>{item?.meeting_count}</div>,
    status:
      item?.active === true ? (
        <div
          className="font-green custom-active-button px-2"
          onClick={() => {
            handleBlockUnBlock(item?.register_id, item?.active);
          }}
        >
          Active
        </div>
      ) : (
        <div
          className="custom-deactive-button px-2"
          onClick={() => {
            handleBlockUnBlock(item?.register_id, item?.active);
          }}
        >
          InActive
        </div>
      ),
  }));
  return (
    <div className="w-100 sidebar-bg rounded" style={{ height: "100%" }}>
      <div className="small-font font-weight-bold px-2 p-1 m-1 th-color">
        <div>Meeting Shedules/Live</div>
      </div>
      <div className="sidebar-bg rounded ">
        <Table columns={cols} data={modifiedMeetingsheduleDetails} />
      </div>
    </div>
  );
}

export default Meetingshedule;
